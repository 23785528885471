<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";

export default {
  methods: {
    toggleFavourite(ele) {
      ele.target.closest('.favourite-btn').classList.toggle("active");
    },
  },
  page: {
    title: "Lead Detail",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Lead Detail",
      items: [
        {
          text: "Pages",
          href: "/",
        },
        {
          text: "Lead",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout
  }
};
</script>

<template>
  <Layout>

    <b-row class="mb-3">
      <b-row>
        <b-col lg="12">
          <div class="tab-content text-muted">
            <div class="tab-pane fade show active" id="project-team" role="tabpanel">

              <div class="team-list list-view-filter">
                <b-card no-body>
                  <b-card-body>
                    <h5 class="card-title mb-3">Información</h5>
                    <div class="table-responsive">
                      <table class="table table-borderless mb-0">
                        <tbody>
                        <tr>
                          <th class="ps-0" scope="row">Full Name :</th>
                          <td class="text-muted">Anna Adame</td>
                        </tr>
                        <tr>
                          <th class="ps-0" scope="row">Mobile :</th>
                          <td class="text-muted">+(1) 987 6543</td>
                        </tr>
                        <tr>
                          <th class="ps-0" scope="row">E-mail :</th>
                          <td class="text-muted">daveadame@velzon.com</td>
                        </tr>
                        <tr>
                          <th class="ps-0" scope="row">Location :</th>
                          <td class="text-muted">
                            California, United States
                          </td>
                        </tr>
                        <tr>
                          <th class="ps-0" scope="row">Joining Date</th>
                          <td class="text-muted">24 Nov 2021</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card-body>
                </b-card>
              </div>

            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>

  </Layout>
</template>
